export const DEVICES = {
  SMALLEST_MOBILE: "smallest_mobile",
  SMALL_MOBILE: "small_mobile",
  MOBILE: "mobile",
  TABLET: "tablet",
  DESKTOP: "desktop",
  LARGE_DESKTOP: "large_desktop",
  LARGER_DESKTOP: "larger_desktop",
};

export const DEVICESArray = Object.values(DEVICES);

export const minBreakpoints = {
  [DEVICES.SMALL_MOBILE]: "(min-width: 321px)",
  [DEVICES.MOBILE]: "(min-width: 480px)",
  [DEVICES.TABLET]: "(min-width: 768px)",
  [DEVICES.DESKTOP]: "(min-width: 1224px)",
  [DEVICES.LARGE_DESKTOP]: "(min-width: 1440px)",
  [DEVICES.LARGER_DESKTOP]: "(min-width: 1920px)",
};

export const maxBreakpoints = {
  [DEVICES.SMALLEST_MOBILE]: "(max-width: 320px)",
  [DEVICES.SMALL_MOBILE]: "(max-width: 479px)",
  [DEVICES.MOBILE]: "(max-width: 767px)",
  [DEVICES.TABLET]: "(max-width: 1223px)",
  [DEVICES.DESKTOP]: "(max-width: 1439px)",
  [DEVICES.LARGE_DESKTOP]: "(max-width: 1919px)",
};
