import React, { ReactNode, useEffect, useState } from "react";

export const GoogleStorageAsset = ({
  className = "",
  src,
  fallback,
  children,
}: {
  className?: string;
  src: string;
  fallback: string;
  children?: ({ url: string }) => ReactNode;
}) => {
  const [url, setUrl] = useState<string>(fallback);

  useEffect(() => {
    if (!src) {
      setUrl(fallback);
      return;
    }
    let finalUrl = src;
    if (finalUrl.indexOf("gs://") > -1) {
      finalUrl = finalUrl.replace("gs://", "https://storage.googleapis.com/");
    }
    setUrl(finalUrl);
  }, [src]);

  if (children) {
    return <>{children({ url: url || fallback })}</>;
  }

  return url ? (
    <img className={className} src={url} />
  ) : (
    <img src={fallback} className={className} />
  );
};
