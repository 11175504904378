/* eslint-disable array-callback-return */
import { useEffect, useState, useMemo } from "react";
import { pick } from "lodash";
import { DEVICES, maxBreakpoints, minBreakpoints } from "./constants";

type TSetState = (r: string) => void;

let listeners: TSetState[] = [] as TSetState[];
let mediaDevice = "";

export const clearMediaDevice = () => {
  mediaDevice = "";
};

const mediaQueries = () => {
  if (mediaDevice) return;

  const deviceChangeListener = (device: string) => {
    return (e: MediaQueryListEvent) => {
      if (e.matches) {
        mediaDevice = device;
        listeners?.forEach(l => l(device));
      }
    };
  };

  const addListener = (device: string, query: string) => {
    const listener = deviceChangeListener(device);
    const media = window.matchMedia(query);
    const data = media.matches;
    if (data) {
      mediaDevice = device;
      listeners?.forEach(l => l(device));
    }
    if (media.addEventListener) {
      media.addEventListener("change", listener);
    } else if (media.addListener) {
      media.addListener(listener);
    }
  };

  addListener(
    DEVICES.SMALLEST_MOBILE,
    `${maxBreakpoints[DEVICES.SMALLEST_MOBILE]}`
  );
  Object.values(
    pick(DEVICES, [
      "SMALL_MOBILE",
      "MOBILE",
      "TABLET",
      "DESKTOP",
      "LARGE_DESKTOP",
    ])
  ).map(size => {
    addListener(size, `${minBreakpoints[size]} and ${maxBreakpoints[size]}`);
  });
  addListener(
    DEVICES.LARGER_DESKTOP,
    `${minBreakpoints[DEVICES.LARGER_DESKTOP]}`
  );
};

const useMediaQuery = () => {
  const [device, setDevice]: [string, TSetState] = useState(mediaDevice);

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  useEffect(() => {
    listeners = [...listeners, setDevice];
    return () =>
      (listeners = (listeners as TSetState[]).filter(f => f !== setDevice));
  }, [setDevice]);

  useEffect(() => {
    mediaQueries();
    setDevice(mediaDevice);
  }, [setDevice]);

  const { isSmallestMobile, isMobile, isTablet, isDesktop, isPhoneOrTablet } =
    useMemo(() => {
      const _isSmallestMobile = device === DEVICES.SMALLEST_MOBILE;
      const _isMobile = [
        DEVICES.SMALLEST_MOBILE,
        DEVICES.SMALL_MOBILE,
        DEVICES.MOBILE,
      ].includes(device);
      const _isTablet = device === DEVICES.TABLET;
      const _isDesktop = !_isTablet && !_isMobile;
      const _isPhoneOrTablet = _isMobile || _isTablet;
      return {
        isSmallestMobile: _isSmallestMobile,
        isMobile: _isMobile,
        isTablet: _isTablet,
        isDesktop: _isDesktop,
        isPhoneOrTablet: _isPhoneOrTablet,
      };
    }, [device]);

  return {
    mediaDevice: device,
    isSmallestMobile,
    isMobile,
    isTablet,
    isDesktop,
    isPhoneOrTablet,
  };
};

export default useMediaQuery;
