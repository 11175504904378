import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export const initialState = {
  sideMenu: {
    mobileVisible: false as boolean,
    panel: null as
      | "notifications"
      | "profile"
      | "integrations"
      | "favorite"
      | null,
    main: null as
      | "integrations"
      | "profile.account"
      | "profile.personalisation"
      | "profile.privacy"
      | "profile.verification"
      | null,
  },
  dialog: null as "login" | "signup" | "resetPassword" | null,
} as const;

const shared = createSlice({
  name: "shared",
  initialState,
  reducers: {
    setSideMenuVisibility: (state, action: PayloadAction<boolean>) => {
      state.sideMenu.mobileVisible = action.payload;
      if (action.payload === false) {
        state.sideMenu.main = null;
        state.sideMenu.panel = null;
      }
    },
    setSideMenu: (
      state,
      action: PayloadAction<typeof initialState["sideMenu"]["panel"]>
    ) => {
      state.sideMenu.panel = action.payload;
    },
    setSideMenuMain: (
      state,
      action: PayloadAction<typeof initialState["sideMenu"]["main"]>
    ) => {
      state.sideMenu.main = action.payload;
    },
    setDialog: (
      state,
      action: PayloadAction<typeof initialState["dialog"]>
    ) => {
      state.dialog = action.payload;
    },
  },
});

export const {
  setSideMenu,
  setSideMenuMain,
  setSideMenuVisibility,
  setDialog,
} = shared.actions;

export default shared.reducer;
